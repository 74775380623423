import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import './top-message-box.scss';

export function TopMessageBox({ iconClassName, children, creativeId }) {
  return (
    <div
      className="top-message-box bg-blue-90 overlay-white-50 d-flex align-items-baseline px-1 text-cool-gray-30 mb-1_5 mb-md-1"
      data-tracking-parent={creativeId}
    >
      {!!iconClassName && <i className={classnames('text-blue-50 mr-1 align-middle', iconClassName)} aria-hidden />}
      <div>{children}</div>
    </div>
  );
}

TopMessageBox.propTypes = {
  iconClassName: PropTypes.string,
  children: PropTypes.node,
  creativeId: PropTypes.string,
};

TopMessageBox.defaultProps = {
  iconClassName: 'icon-notification2',
  children: null,
  creativeId: null,
};
