import { get, uniq, compact, includes, isEmpty, mapValues, isString, pick } from 'lodash';
import PropTypes from 'prop-types';
import { numberWithCommas, buildStringValue } from 'client/site-modules/shared/utils/string';
import { getWordWithRightArticle } from 'client/utils/string-utils';
import { getMonthYear } from 'client/site-modules/shared/utils/date-utils';
import { INVENTORY_TYPES, INVENTORY_TYPES_CAPITALIZED } from 'client/constants/inventory-types';
import { PAGE_NUMBER as SEO_PAGE_NUMBER } from 'site-modules/shared/constants/allowed-seo-srp-request-params';
import {
  isNationalSRP,
  isLeaseSRP,
  isUsedCheapCarsSRP,
  isCheapLeaseSRP,
  isNewFlatUrlSRP,
  isUsedCarsUnderPriceSRP,
  isUsedAllSRP,
  isCarFinderSRP,
  isUsedByOwnerSRP,
  isMakeDealershipSRP,
  isMakeDealershipCityStateSRP,
  isUsedCarsStateSRP,
  isMakeModelLeaseDealsLocationSRP,
  isCoreSrpMMYS,
  isForSaleSRP,
} from 'site-modules/shared/utils/inventory/srp-type-checkers';
import { srpLinkBuilder } from 'site-modules/shared/utils/srp-link-constructor';
import { replaceToSeoKeys } from 'site-modules/shared/utils/inventory/params-conversion';
import { getBaseBreadcrumb, getCarsForSaleBreadcrumbs } from 'site-modules/shared/components/breadcrumbs/helper';
import {
  getLeaseMakeModelBreadcrumbs,
  getLeaseMakeBreadcrumbs,
  getLeaseTypeBreadcrumbs,
  getLeaseCarsForSaleBreadcrumbs,
  getCarsForSaleFlatBreadcrumbs,
  getMakeBreadcrumbs,
  getMakeModelBreadcrumbs,
  getTypeBreadcrumbs,
} from 'site-modules/shared/components/breadcrumbs/srp-helper';
import * as URL_PATTERNS from 'site-modules/shared/constants/inventory/srp-url-patterns';
import { formatPriceString } from 'client/site-modules/shared/utils/price-utils';
import { getUrlPattern } from 'site-modules/shared/utils/inventory/srp-utils';
import { getPlural } from 'client/utils/plural';
import { PUB_STATES, PUB_STATES_LOWERCASE } from 'client/constants/pub-states';
import { adjustStateName } from 'client/site-modules/shared/utils/adjust-state-name';
import { PERSIST_URL_PATHNAME_FILTERS } from 'site-modules/shared/utils/inventory/search-filter';

const WHITE_SPACE_REGEX = /\s+/g;
const DEFAULT_BREADCRUMB_INVENTORY_TYPE = INVENTORY_TYPES_CAPITALIZED.USED;
export const ELECTRIC_TYPE_SENTENCE = 'Electric Cars, Trucks and SUV';

export const compactSrpTitleParts = titleParts =>
  compact(Object.values(titleParts))
    .join(' ')
    .replace(WHITE_SPACE_REGEX, ' ')
    .trim();

const trimSrpTitleParts = titleParts =>
  mapValues(titleParts, val => (isString(val) ? val.replace(WHITE_SPACE_REGEX, ' ').trim() : val));

/**
 * generate title type part
 * @param {Array} types
 * @returns {String}
 */
export function generateTitleInventoryType(types) {
  const isNewType = includes(types, INVENTORY_TYPES.NEW);
  const isCpoType = includes(types, INVENTORY_TYPES.CPO);
  const isUsedType = includes(types, INVENTORY_TYPES.USED);
  let type = '';

  if (isNewType && !isUsedType && !isCpoType) {
    type = 'New';
  } else if (!isNewType && isUsedType) {
    type = 'Used';
  } else if (!isNewType && !isUsedType && isCpoType) {
    type = 'Used Certified Pre-Owned';
  }

  return type;
}

/**
 * generate title location part
 * @param {Object} location
 * @param {String} prefix
 * @returns {String}
 */
export function generateTitleLocation({ city = '', stateCode = '', stateName = '' }, prefix = 'in') {
  if (city && stateCode) {
    return `${prefix} ${city}, ${stateCode}`;
  }
  return stateName ? `${prefix} ${adjustStateName({ stateName })}` : '';
}

/**
 * Generate title for /make-dealership-city-state/ pages
 * @param {String} make
 * @param {Object} visitorLocation
 * @param {Boolean} isMeta
 * @param {String} lastPart
 * @returns {Object}
 */
export function generateMakeDealershipCityStateTitle({ make, visitorLocation, isMeta, lastPart }) {
  const city = get(visitorLocation, 'city');
  const stateCode = get(visitorLocation, 'stateCode');
  const generalPart = `${make} Dealership`;
  const locationPart = generateTitleLocation({ city, stateCode });
  const finalPart = isMeta ? `| ${make} Dealers Near You ${lastPart}`.trim() : '';

  return { generalPart, locationPart, finalPart };
}

/**
 * Generates lease title for page and for H1.
 * @param {string} make
 * @param {string} model
 * @param {string} type
 * @param {boolean} isMeta
 * @param {string} pagesPart
 * @returns {string}
 */
export function generateLeaseTitle({ make, model, type, isMeta = false, pagesPart }) {
  const vehicle = buildStringValue(make, model, type);
  const isElectricType = vehicle.includes(ELECTRIC_TYPE_SENTENCE);

  if (isElectricType && isMeta) {
    return 'Best Electric Car, Truck and SUV Lease Deals - Lease An Electric Car With Edmunds';
  }

  let title = '';

  if (pagesPart) {
    title = isMeta ? `Best ${vehicle} Lease Deals` : `Best ${vehicle} Lease Deals & Specials`;
  } else {
    title = isMeta
      ? `Best ${vehicle} Lease Deals & Specials - Lease ${getWordWithRightArticle(vehicle || 'Car')} With Edmunds`
      : `Best ${vehicle} Lease Deals & Specials`;
  }

  return title.trim().replace(WHITE_SPACE_REGEX, ' ');
}

/**
 * Generates NEW title for page and for H1.
 * @returns {string}
 */
export function generateNewSrpTitle({ make, model, latestYear, vehicleCategory, isMeta, lastPart = '', urlLocation }) {
  const pluralValue = undefined;
  const pluralCounter = undefined;
  const lowerCasePluralEnding = true;

  const stateName = get(urlLocation, 'stateName', '');
  const stateCode = get(urlLocation, 'stateCode', '');
  const cityName = get(urlLocation, 'city', '');

  const greatDealPart = isMeta && stateName && !cityName && 'Get a Great deal on a';

  const generalPart = compact([
    greatDealPart,
    'New',
    !make && !vehicleCategory && 'Cars',
    !greatDealPart && isMeta && make && model && !cityName && latestYear,
    make,
    model,
    vehicleCategory &&
      getPlural(
        vehicleCategory === 'Luxury' ? 'Luxury Vehicle' : vehicleCategory,
        pluralValue,
        pluralCounter,
        lowerCasePluralEnding
      ),
    'for Sale',
  ]).join(' ');

  const defaultLocationPart = 'Near Me';
  const specificLocationPart = cityName
    ? `in ${cityName}, ${stateCode}`
    : `in ${adjustStateName({ stateName, isTitleCase: true })}`;

  const metaPart = isMeta && make && model && !stateName && !cityName && '(with Photos)';

  return {
    generalPart,
    locationPart: stateName ? specificLocationPart : defaultLocationPart,
    finalPart: compactSrpTitleParts({ metaPart, lastPart }),
  };
}

/**
 * Generates title for srp page with non-flat url and for H1.
 * @returns {string}
 */
export function generateNonFlatUrlSrpTitle({ locationPart, lastPart, seoData, isMeta }) {
  const { make, model, inventoryTypes, inventoryType, rooftopId, locationText } = seoData;
  const carsPart = !make ? 'Cars' : null;
  const hideSpecialOffer =
    !isMeta || (inventoryTypes.length === 1 && inventoryTypes[0] === INVENTORY_TYPES.CPO) || locationText;
  const specialOffersPart = !hideSpecialOffer ? '- Special Offers' : null;
  const nearYouPart = locationPart || specialOffersPart || rooftopId ? '' : 'Near Me';

  const generalPart = compactSrpTitleParts({
    inventoryType,
    make,
    model,
    carsPart,
    forSalePart: 'for Sale',
  });
  const finalPart = compactSrpTitleParts({
    specialOffersPart,
    lastPart,
  });

  return {
    generalPart,
    locationPart: locationPart || nearYouPart,
    finalPart,
  };
}

/**
 * Generates title for srp page flat urls and for H1.
 * @returns {string}
 */
function generateCommonFlatUrlsSrpTitle({
  locationPart,
  vehicleCategory,
  pluralVehicleCategory,
  lastPart,
  seoData,
  isMeta,
}) {
  const {
    years,
    year,
    urlPattern,
    maxPrice,
    make,
    model,
    inventoryType,
    color,
    rooftopId,
    inventoryTypes,
    locationText,
  } = seoData;
  let yearPart = null;

  /* note: we only add a year to the title if it is an actual number.
           we do not want to parrot back random user-entered strings or * from the url. */
  if (years.length === 2) {
    yearPart = years.every(yr => Number(yr) > 0) ? years.join(' through ') : null;
  } else if (year || years.length === 1) {
    yearPart = Number(year || years[0]) > 0 ? year || years[0] : null;
  }
  const bestPart = isUsedCarsUnderPriceSRP(urlPattern) && parseInt(maxPrice, 10) === 5000 ? 'Best' : null;
  const carsPart = !make && !vehicleCategory ? 'Cars' : null;
  const forSalePart = bestPart && isMeta ? null : 'for Sale';
  const maxPricePart = maxPrice && `Under ${formatPriceString(parseInt(maxPrice, 10))}`;
  const pricingInfoPart = maxPricePart && !(bestPart && isMeta) ? '- Vehicle Pricing Info' : null;
  const hideSpecialOffer =
    !isMeta ||
    (inventoryTypes.length === 1 && inventoryTypes[0] === INVENTORY_TYPES.CPO) ||
    color ||
    maxPricePart ||
    locationText ||
    urlPattern.includes('used') ||
    urlPattern === URL_PATTERNS.CORE_SRP_URL_PATTERN;
  const specialOffersPart = !hideSpecialOffer ? '- Special Offers' : null;
  const nearYouPart = locationPart || specialOffersPart || pricingInfoPart || rooftopId || bestPart ? '' : 'Near Me';

  const generalPart = compactSrpTitleParts({
    bestPart,
    inventoryType,
    color,
    yearPart,
    make,
    model,
    type: pluralVehicleCategory || vehicleCategory,
    carsPart,
    maxPricePart,
    forSalePart,
  });
  const finalPart = compactSrpTitleParts({
    pricingInfoPart,
    specialOffersPart,
    lastPart,
  });

  return {
    generalPart,
    locationPart: locationPart || nearYouPart,
    finalPart,
  };
}

function getVehicleCategory({ vehicleCategories, urlContext, searchResultsFilter, inventories }) {
  const trimDisplayName = get(urlContext, 'attributes.inventory.trimDisplayName');
  const isTrim = get(searchResultsFilter, 'trim', []).length === 1;
  const categoryValue = get(vehicleCategories, '[0]', '');

  if (trimDisplayName) return trimDisplayName;

  if (vehicleCategories.length !== 1) {
    return '';
  }

  return isTrim && Array.isArray(inventories) ? get(inventories[0], 'vehicleInfo.styleInfo.trim', '') : categoryValue;
}

export const SearchFilterSeoObjectEntities = PropTypes.shape({
  make: PropTypes.string,
  model: PropTypes.string,
  submodel: PropTypes.string,
  submodelFromUrlContext: PropTypes.string,
  vehicleCategories: PropTypes.arrayOf(PropTypes.string),
  vehicleCategory: PropTypes.string,
  pubState: PropTypes.string,
  colors: PropTypes.arrayOf(PropTypes.string),
  color: PropTypes.string,
  maxPrice: PropTypes.string,
  years: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
  year: PropTypes.string,
  latestYear: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  locationText: PropTypes.string,
  radius: PropTypes.string,
  dealerName: PropTypes.string,
  rooftopId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  urlPattern: PropTypes.string,
  inventoryType: PropTypes.string,
  inventoryTypes: PropTypes.arrayOf(PropTypes.string),
  national: PropTypes.bool,
  isLease: PropTypes.bool,
  isCarFinder: PropTypes.bool,
  inventoryCount: PropTypes.number,
  urlLocation: PropTypes.shape({}),
  pageNumber: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
});

/**
 * @param searchResultsFilter {object} --- collection of params from query or url api
 * @param urlContext {object} --- data from url api
 * @param inventories {object[]} --- Search results
 * @param attributes {object} --- collection of params from query with readable values
 * @param inventoryCount {number} --- collection of params from query with readable values
 * @param pageNumber {number} --- collection of params from query with readable values
 */
export function buildSearchFilterSEOObject({
  searchResultsFilter,
  urlContext,
  inventories,
  attributes,
  inventoryCount,
  pageNumber,
}) {
  const urlPattern = getUrlPattern(urlContext);
  const urlLocation = get(urlContext, 'location', {});
  const locationText = generateTitleLocation(urlLocation);
  const dealerName = get(attributes, 'dealerInfo.name', '');
  // Selected filters
  const rooftopId = get(attributes, 'dealerInfo.rooftopId', '');
  const years = compact(uniq(get(searchResultsFilter, 'year[0]', '').split('-')));
  const radius = get(searchResultsFilter, 'radius[0]', '');
  const paymentType = get(searchResultsFilter, 'paymentType[0]', '');

  const year = `${get(attributes, 'vehicle.modelYear.year', '')}`;
  const submodelFromUrlContext = get(urlContext, 'vehicle.submodel.name', '');
  const maxPrice = get(attributes, 'maxPrice', '');
  const colors = compact(get(attributes, 'exteriorColor', '').split(','));
  // Color are displayed for flat URLs only based on flat URL data
  // In order to avoid displaying of OEM colors from facets
  const color = urlPattern ? get(colors, '[0]', '') : '';
  const inventoryTypes = compact(get(attributes, 'inventoryType', '').split(','));
  const inventoryType = generateTitleInventoryType(inventoryTypes);
  const publicationState = get(attributes, 'vehicle.publicationState', '');
  const pubState = !publicationState || publicationState === PUB_STATES.NEW_USED ? '' : `${publicationState} `;
  const vehicleCategories = compact(get(attributes, 'type', '').split(','));
  const vehicleCategory = getVehicleCategory({ vehicleCategories, urlContext, searchResultsFilter, inventories });
  const make = get(attributes, 'vehicle.make.name', '');
  const modelName = attributes?.vehicle?.model?.name ?? '';
  const selectedFacetsModel = searchResultsFilter?.model ?? [];
  const model =
    modelName && selectedFacetsModel?.some(e => /mfn\*.+/.test(e))
      ? attributes?.vehicle?.model?.modelFamilyName ?? ''
      : modelName;
  const submodel = get(searchResultsFilter, 'submodelId[0]', '');
  const latestYear =
    urlPattern && inventoryType === 'New' && Array.isArray(inventories)
      ? inventories.reduce((result, vehicle) => {
          const vinYear = get(vehicle, 'vehicleInfo.styleInfo.year');
          return vinYear > result ? vinYear : result;
        }, 0)
      : null;

  return {
    make,
    model,
    submodel,
    submodelFromUrlContext,
    vehicleCategories,
    vehicleCategory,
    pubState,
    colors,
    color,
    maxPrice,
    years,
    year,
    latestYear,
    locationText,
    radius,
    dealerName,
    rooftopId,
    urlPattern,
    inventoryType,
    inventoryTypes,
    national: isNationalSRP(urlContext),
    isLease: isLeaseSRP(urlContext) || paymentType === 'lease',
    isCarFinder: !rooftopId && isCarFinderSRP(searchResultsFilter),
    inventoryCount,
    urlLocation,
    pageNumber,
  };
}

/**
 * Generate SEO title, depends searchResultsFilter params and FlatUrlLocation
 * @param seoData {object} --- collection of params from query or flatUrl api
 * @param visitorLocation {object}
 * @param isMeta {boolean} --- adding some specific data for meta
 * @param returnParts
 * @param isUsedCoreComboTitle {boolean}
 * @returns {string}
 */

export function generateSEOTitle({
  seoData,
  visitorLocation,
  isMeta,
  returnParts,
  isUsedCoreComboTitle,
  isSeot4456Enabled,
}) {
  const {
    make,
    model = '',
    latestYear,
    year,
    submodelFromUrlContext,
    rooftopId,
    dealerName,
    urlPattern,
    locationText,
    national,
    isLease,
    inventoryCount,
    isCarFinder,
    urlLocation,
    pageNumber,
  } = seoData;
  let vehicleCategory = seoData.vehicleCategory || '';
  let pluralVehicleCategory;
  let titleParts = {};
  const locationPart =
    locationText ||
    (!urlPattern && !national && (visitorLocation ? generateTitleLocation(visitorLocation, 'Near') : ''));
  const stateUrlLocation = get(urlLocation, 'stateName', '');
  const cityUrlLocation = get(urlLocation, 'city', '');
  const pagesPart =
    isMeta && pageNumber > 1 && urlPattern && !stateUrlLocation && !cityUrlLocation
      ? `- Pg. ${numberWithCommas(pageNumber)}`
      : '';
  const withPhotos = isSeot4456Enabled ? ' (with Photos)' : '';
  const lastPart = isMeta ? `${pagesPart}${withPhotos} | Edmunds` : '';

  if (vehicleCategory.toLowerCase() === 'electric') {
    vehicleCategory = ELECTRIC_TYPE_SENTENCE;
    pluralVehicleCategory = `${ELECTRIC_TYPE_SENTENCE}s`;
  }

  switch (true) {
    case isForSaleSRP(urlPattern): {
      titleParts = {
        generalPart: `${year} ${make} ${model} for Sale`,
        locationPart: 'Near Me',
        finalPart: lastPart,
      };
      break;
    }
    case isUsedCoreComboTitle: {
      titleParts = {
        generalPart: isMeta ? `${year} ${make} ${model} Price, Ratings & For Sale` : `${year} ${make} ${model}`,
        finalPart: lastPart,
      };
      break;
    }
    case isCarFinder: {
      titleParts = {
        generalPart: isMeta
          ? 'Edmunds Car Finder - How to Choose A Car'
          : 'What Kind of Car Should I Get? Try Edmunds Car Finder to Find a Car',
        finalPart: pagesPart,
      };
      break;
    }
    case Boolean(rooftopId && dealerName): {
      titleParts = {
        generalPart: dealerName,
        finalPart: lastPart,
      };
      break;
    }
    case isCoreSrpMMYS(urlPattern):
      titleParts = {
        generalPart: `Used ${year} ${make} ${model} ${submodelFromUrlContext} for Sale`,
      };
      break;
    case isMakeDealershipSRP(urlPattern): {
      titleParts = {
        generalPart: `${make} Dealership`,
        finalPart: lastPart,
      };
      break;
    }
    case isMakeDealershipCityStateSRP(urlPattern): {
      titleParts = generateMakeDealershipCityStateTitle({ make, visitorLocation, isMeta, lastPart });
      break;
    }
    case isUsedAllSRP(urlPattern): {
      titleParts = {
        generalPart: 'Find The Best Used Cars',
        locationPart: 'Near Me',
        finalPart: `${pagesPart} | Edmunds Used Car Finder`,
      };
      break;
    }
    case isUsedByOwnerSRP(urlPattern): {
      titleParts = {
        generalPart: 'Cars for Sale by Owner',
        locationPart: 'Near Me',
        finalPart: lastPart,
      };
      break;
    }
    case isUsedCheapCarsSRP(urlPattern): {
      const cheapCarsTitle = 'Cheap Cars For Sale';
      titleParts = {
        generalPart: isMeta ? `${cheapCarsTitle}: Special Offers & Pricing` : cheapCarsTitle,
        finalPart: lastPart,
      };
      break;
    }
    case isCheapLeaseSRP(urlPattern): {
      const cheapLeaseTitle = 'Cheap Lease Deals';
      titleParts = {
        generalPart: isMeta
          ? `${numberWithCommas(inventoryCount)} ${cheapLeaseTitle}: Cheapest Car Lease Deals In ${getMonthYear()}`
          : cheapLeaseTitle,
        finalPart: lastPart,
      };
      break;
    }
    case isNewFlatUrlSRP(urlPattern): {
      titleParts = generateNewSrpTitle({
        make,
        model,
        latestYear,
        vehicleCategory,
        isMeta,
        lastPart,
        urlLocation,
        pagesPart,
      });

      break;
    }
    case isUsedCarsStateSRP(urlPattern): {
      titleParts = {
        generalPart: `Used Cars for Sale`,
        locationPart: locationText,
        finalPart: lastPart,
      };
      break;
    }
    case isMakeModelLeaseDealsLocationSRP(urlPattern): {
      titleParts = {
        generalPart:
          isMeta && !get(urlLocation, 'city', '')
            ? `Find The Best ${make} ${model} Lease Deals`
            : `Best ${make} ${model} Lease Deals`,
        locationPart: locationText,
        finalPart: lastPart,
      };
      break;
    }
    case isLease: {
      titleParts = {
        generalPart: generateLeaseTitle({
          make,
          model,
          type: pluralVehicleCategory || vehicleCategory,
          isMeta,
          pagesPart,
        }),
        locationPart: isMeta ? '' : locationPart,
        finalPart: pagesPart ? lastPart : '',
      };
      break;
    }
    case urlPattern === URL_PATTERNS.MAKE_TYPE_SRP_URL_PATTERN: {
      titleParts = {
        generalPart: `${make} ${vehicleCategory}s for Sale`,
        finalPart: isMeta ? `– Photos, Prices & Reviews ${lastPart}` : '',
        locationPart: isMeta ? '' : 'Near Me',
      };
      break;
    }
    case urlPattern !== '': {
      titleParts = generateCommonFlatUrlsSrpTitle({
        locationPart,
        vehicleCategory,
        pluralVehicleCategory,
        lastPart,
        seoData,
        isMeta,
      });
      break;
    }
    default: {
      titleParts = generateNonFlatUrlSrpTitle({
        seoData,
        lastPart,
        locationPart,
        isMeta,
      });
      break;
    }
  }

  return returnParts ? trimSrpTitleParts(titleParts) : compactSrpTitleParts(titleParts);
}

export function generateSEOKeywords(urlContext, seoData) {
  const { urlPattern, color, make, model, vehicleCategory, years, maxPrice, inventoryTypes } = seoData;
  const genericKeywords = 'Most Popular Models, Overviews, Articles, Forum, Edmunds';
  const baseKeywords = [
    'for sale',
    'special offer',
    'discounts',
    'deals',
    'savings',
    'used cars',
    'car inventory',
    'best prices',
  ];
  if (!urlPattern) {
    return genericKeywords;
  }
  const locationParams = ['city', 'stateCode'];
  const urlDataKeywords = locationParams.map(param => get(urlContext, `location.${param}`, '').toLowerCase());

  return compact([
    ...inventoryTypes.map(type => type.toLowerCase()),
    make.toLowerCase(),
    model.toLowerCase(),
    vehicleCategory.toLowerCase(),
    color.toLowerCase(),
    maxPrice && `$${maxPrice}`,
    ...years,
    ...urlDataKeywords,
    ...baseKeywords,
  ]).join(', ');
}

function excludeLastLinkFromBreadcrumbs(breadcrumbs) {
  return breadcrumbs.map((breadcrumb, index) =>
    index + 1 !== breadcrumbs.length
      ? breadcrumb
      : {
          title: breadcrumb.title,
        }
  );
}

export function generateSEOBreadcrumbs({ originalUrl, seoData, includeLastLink }) {
  const { urlPattern, locationText, color, make, model, vehicleCategory, year, inventoryType, maxPrice } = seoData;
  const baseBreadcrumb = getBaseBreadcrumb(
    generateSEOTitle({ seoData, visitorLocation: {}, isMeta: false }).replace(/\s-.*/g, ''),
    originalUrl
  );
  const isLease = seoData.isLease;
  const invType = inventoryType || DEFAULT_BREADCRUMB_INVENTORY_TYPE;

  if (!urlPattern) {
    return excludeLastLinkFromBreadcrumbs(getCarsForSaleBreadcrumbs());
  }

  let breadcrumbs;
  if (make && model) {
    breadcrumbs = isLease
      ? getLeaseMakeModelBreadcrumbs(make, model, DEFAULT_BREADCRUMB_INVENTORY_TYPE)
      : getMakeModelBreadcrumbs(make, model, invType);
  } else if (make) {
    breadcrumbs = isLease
      ? getLeaseMakeBreadcrumbs(make, DEFAULT_BREADCRUMB_INVENTORY_TYPE)
      : getMakeBreadcrumbs(make, invType);
  } else if (vehicleCategory) {
    breadcrumbs = isLease
      ? getLeaseTypeBreadcrumbs(vehicleCategory, DEFAULT_BREADCRUMB_INVENTORY_TYPE)
      : getTypeBreadcrumbs(vehicleCategory, invType);
  } else {
    breadcrumbs = isLease ? getLeaseCarsForSaleBreadcrumbs() : getCarsForSaleFlatBreadcrumbs(invType);
  }
  if (locationText || year || color || maxPrice || isUsedAllSRP(urlPattern) || isUsedCarsStateSRP(urlPattern)) {
    breadcrumbs = [...breadcrumbs, baseBreadcrumb];
  }
  return includeLastLink ? breadcrumbs : excludeLastLinkFromBreadcrumbs(breadcrumbs);
}

function generateBaseDescription({ descPart, seoInfo, locationText, totalNumber, inventoryType }) {
  const minDisplayPrice = get(seoInfo, 'minDisplayPrice');
  const maxDiscount = get(seoInfo, 'maxDiscount');
  const nationalVinCount = get(seoInfo, 'nationalVinCount');

  const totalNumberString = totalNumber && numberWithCommas(totalNumber);
  const publicationState = inventoryType.toLowerCase();
  const vinCountString = nationalVinCount && numberWithCommas(nationalVinCount);
  const minPriceString = minDisplayPrice && formatPriceString(minDisplayPrice);
  const maxDiscountString = maxDiscount && maxDiscount > 1499 ? `up to ${formatPriceString(maxDiscount)}` : 'money';
  const locationString = locationText || 'near you';
  const isUsed = publicationState === PUB_STATES_LOWERCASE.USED;

  const findCarText =
    isUsed && totalNumber
      ? 'Find your perfect car with Edmunds expert reviews, car comparisons, and pricing tools.'
      : 'Find your perfect car with Edmunds expert and consumer car reviews, dealer reviews, car comparisons and pricing tools.';
  const savingsText = totalNumber
    ? `Save ${maxDiscountString} on ${totalNumber > 1 ? 'one of ' : ''}${totalNumberString}${
        publicationState ? ` ${publicationState}` : ''
      } ${descPart} ${locationString}.`
    : `See any available${publicationState ? ` ${publicationState}` : ''} ${descPart} for sale.`;
  const countsText =
    nationalVinCount && minDisplayPrice && !isUsed
      ? `We have ${vinCountString} new and used cars in our database with prices starting as low as ${minPriceString}.`
      : '';
  return compact([savingsText, findCarText, countsText]).join(' ');
}

/**
 * Generates description meta for lease deals SRP.
 * @param {string} make
 * @param {string} model
 * @param {string} type
 * @returns {string}
 */
function generateLeaseDescription(make, model, type) {
  const vehicle = buildStringValue(make, model, type);
  return `Find the best ${vehicle} lease deals on Edmunds. Lease a ${vehicle ||
    'new model'} using current special offers, deals, and more. Learn about leasing offers including term, mileage, down payment, and monthly prices.`.replace(
    WHITE_SPACE_REGEX,
    ' '
  );
}

export function generateNewSrpDescription({
  make = '',
  model = '',
  vehicleCategory = '',
  totalNumber,
  seoInfo,
  urlLocation,
}) {
  const pluralValue = undefined;
  const pluralCounter = undefined;
  const lowerCasePluralEnding = true;

  const totalNumberString = totalNumber && numberWithCommas(totalNumber);
  const maxDiscount = get(seoInfo, 'maxDiscount');
  const dealPart = maxDiscount ? `Save up to ${formatPriceString(maxDiscount)}` : 'Get a great deal';
  const vehicleString = `${make} ${model} ${vehicleCategory}`.trim();
  const vehiclePart =
    (make && model) || vehicleCategory
      ? `a new ${vehicleString}`
      : `new${make ? ` ${make}` : ''} cars, trucks, or SUVs`;

  const stateName = get(urlLocation, 'stateName', '');
  const stateCode = get(urlLocation, 'stateCode', '');
  const cityName = get(urlLocation, 'city', '');

  const defaultLocationPart = 'for sale near you';
  const specificLocationPart = cityName
    ? `in ${cityName}, ${stateCode}`
    : `in ${adjustStateName({ stateName, isTitleCase: false })}`;

  const locationPart = stateName ? specificLocationPart : defaultLocationPart;

  return compact([
    dealPart,
    'on',
    specificLocationPart && totalNumber && make && model
      ? `one of ${totalNumberString} new ${getPlural(
          `${make} ${model}`,
          pluralValue,
          pluralCounter,
          lowerCasePluralEnding
        )}`
      : vehiclePart,
    `${locationPart}.`,
    stateName
      ? 'Find your perfect car with Edmunds expert reviews, car comparisons, and pricing tools.'
      : 'Get dealership reviews, prices, ratings. Edmunds gives you everything you need to get a great deal.',
  ]).join(' ');
}

export function generateSEODescription(totalNumber, seoInfo, seoData, inventory = []) {
  const {
    urlPattern,
    locationText,
    color,
    make,
    model,
    submodel,
    year,
    years,
    inventoryType,
    isLease,
    isCarFinder,
    urlLocation,
    submodelFromUrlContext,
  } = seoData;
  const minDisplayPrice = get(seoInfo, 'minDisplayPrice', 0);
  const maxDiscount = get(seoInfo, 'maxDiscount', 0);

  if (isCarFinder) {
    return 'Which car is right for you? Edmunds provides the best car finder tools available to help you make an informed decision about how to choose a car.';
  }

  if (isForSaleSRP(urlPattern)) {
    const pluralCounter = undefined;
    const lowerCasePluralEnding = true;
    const publicationState = inventoryType.toLowerCase();

    return `Save up to ${formatPriceString(maxDiscount)} on${totalNumber > 1 ? ' one of' : ''} ${numberWithCommas(
      totalNumber
    )}${publicationState ? ` ${publicationState}` : ''} ${year} ${getPlural(
      `${make} ${model}`,
      totalNumber,
      pluralCounter,
      lowerCasePluralEnding
    )} near you. Find your perfect car with Edmunds expert reviews, car comparisons, and pricing tools.`;
  }

  if (isUsedByOwnerSRP(urlPattern)) {
    return `See all used cars for sale by owner near you. There are ${numberWithCommas(
      totalNumber
    )} cars for sale by owner starting at ${formatPriceString(minDisplayPrice)}.`;
  }

  if (isMakeDealershipSRP(urlPattern)) {
    return `Find all ${make} dealerships near you and save money by comparing dealership listings. Find your perfect car with Edmunds expert reviews, car comparisons, and pricing tools.`;
  }

  if (isMakeDealershipCityStateSRP(urlPattern)) {
    return `See all ${make} dealerships near you in ${locationText} and save money by comparing ${make} dealer listings. Find your perfect car with Edmunds expert reviews, car comparisons, and pricing tools.`;
  }

  if (isUsedCarsStateSRP(urlPattern)) {
    return `Save up to ${formatPriceString(maxDiscount)} on one of ${numberWithCommas(
      totalNumber
    )} used cars for sale in ${
      urlLocation.stateName
    }. Find your perfect car with Edmunds expert reviews, car comparisons, and pricing tools.`;
  }

  if (isCoreSrpMMYS(urlPattern)) {
    return `Save up to ${formatPriceString(maxDiscount)} on one of ${numberWithCommas(
      totalNumber
    )} used ${year} ${make} ${model} ${getPlural(
      submodelFromUrlContext,
      totalNumber,
      undefined,
      true
    )} near you. Find your perfect car with Edmunds expert reviews, car comparisons, and pricing tools.`;
  }

  let vehicleCategory = seoData.vehicleCategory || '';

  const pluralValue = undefined;
  const counter = totalNumber || 2; // Still use plural value if there are no inventory
  const lowerCase = true;
  let pluralVehicleCategory = getPlural(vehicleCategory, pluralValue, counter, lowerCase);

  if (vehicleCategory.toLowerCase() === 'electric') {
    vehicleCategory = 'electric cars, trucks and SUVs';
    pluralVehicleCategory = vehicleCategory;
  }

  if (isMakeModelLeaseDealsLocationSRP(urlPattern)) {
    const minLeasePayment = inventory.reduce((result, vin) => {
      const monthlyPayment = get(vin, 'prices.estimateLeasePromise.monthlyPayment', 0);
      return !result || (monthlyPayment && monthlyPayment < result) ? monthlyPayment : result;
    }, 0);

    const paymentText =
      minLeasePayment > 0 ? ` for as little as ${formatPriceString(minLeasePayment)} per month with $1000 down` : '';

    return `Lease a new ${make} ${model} ${locationText}${paymentText}. Find your perfect car with Edmunds expert reviews, car comparisons, and pricing tools.`;
  }

  if (isLease) return generateLeaseDescription(make, model, vehicleCategory);

  if (isNewFlatUrlSRP(urlPattern)) {
    return generateNewSrpDescription({ make, model, vehicleCategory, seoInfo, urlLocation, totalNumber });
  }

  if (urlPattern === URL_PATTERNS.MAKE_TYPE_SRP_URL_PATTERN) {
    return `Find the best ${make} ${pluralVehicleCategory} near you. Get reviews, photos, and pricing information for the most popular ${make} ${pluralVehicleCategory}.`;
  }

  if (isUsedAllSRP(urlPattern)) {
    return `Edmunds offers the best used car finder tools available to help you find the best used cars near you.`;
  }

  const typeVariations = ['minivan', 'suv', 'truck'];
  let customizer = '';

  if (make && model && submodel) {
    customizer = `${make} ${model} ${getPlural(submodel, pluralValue, counter, lowerCase)}`;
  } else if (make && model && vehicleCategory && urlPattern) {
    customizer = `${make} ${model} ${pluralVehicleCategory}`;
  } else if (make && model) {
    customizer = `${make} ${getPlural(model, pluralValue, counter, lowerCase)}`;
  } else if (make && vehicleCategory) {
    customizer = `${make} ${pluralVehicleCategory}`;
  } else if (make) {
    customizer = getPlural(make, pluralValue, counter, lowerCase);
  } else if (vehicleCategory) {
    customizer =
      includes(typeVariations, vehicleCategory.toLowerCase()) || vehicleCategory.toLowerCase().includes('electric')
        ? pluralVehicleCategory
        : `${vehicleCategory} cars`;
  } else {
    customizer = 'cars for sale';
  }

  if (year || (years && years.length === 1)) {
    customizer = `${year || years[0]} ${customizer}`;
  }
  if (color && urlPattern) {
    customizer = `${color} ${customizer}`;
  }
  if (urlPattern === 'used-make-model-city-state' && !customizer.includes('for sale')) {
    customizer = `${customizer} for sale`;
  }

  return generateBaseDescription({
    descPart: customizer,
    seoInfo,
    locationText,
    totalNumber,
    inventoryType,
  });
}

export function getPaginationUrls({ pageNumber, pagesCount, selectedFacets, urlContext, pathName }) {
  const isPrevDisabled = Number(pageNumber) === 1;
  const isPrevNumberHidden = Number(pageNumber) === 2;
  const isNextDisabled = Number(pageNumber) === pagesCount;

  let prevLink;
  let nextLink;

  /*
    For flat urls (ie. isEmpty(urlContext)) we only want to keep a subset of selected facets present in the url.
    These parameters can be found in PERSIST_URL_PATHNAME_FILTERS.
  */
  const requestParams = isEmpty(urlContext) ? selectedFacets : pick(selectedFacets, PERSIST_URL_PATHNAME_FILTERS);

  if (!isPrevDisabled) {
    // For the first page, page number should not be in parameters list
    const prevPageNumber = !isPrevNumberHidden ? pageNumber - 1 : null;
    prevLink = srpLinkBuilder(replaceToSeoKeys({ ...requestParams, [SEO_PAGE_NUMBER]: prevPageNumber }), pathName);
  }
  if (!isNextDisabled) {
    nextLink = srpLinkBuilder(replaceToSeoKeys({ ...requestParams, [SEO_PAGE_NUMBER]: pageNumber + 1 }), pathName);
  }

  return {
    prev: prevLink,
    next: nextLink,
  };
}

/**
 * Get Robots object
 * “noindex” tags to flat SRP URLs with low search traffic
 * The rules for the noindex tags in the Groundwork SRP are located here: https://gitlab.shared-services.accounts.edmunds.com/edmunds/cms-edmunds-prod/tree/master/edmunds/content/seo-whitelists/srp
 * if inventory/srp.html has not params after .html, returns undefined
 * otherwise if inventory/srp.html has at least one parameter in the request URL we set noIndex true
 * @param searchResultsFilter {object}
 * @param urlContext {object}
 * @param seoInfo { object }
 * @returns {object} or {undefined}
 */

export function getRobots(searchResultsFilter, urlContext, seoInfo) {
  const noIndex = get(seoInfo, 'noindex', false);
  const redirectUrl = get(urlContext, 'redirectUrl', '');
  const isSrpOnMakeType = get(urlContext, 'isSrpOnMakeType', '');

  // Add noindex and nofollow when redirectUrl is present
  if (redirectUrl) return { noIndex: true, noFollow: true };

  if (isSrpOnMakeType || isEmpty(searchResultsFilter) || (!isEmpty(urlContext) && !noIndex)) {
    return undefined;
  }

  return isEmpty(urlContext) ? { noIndex, noFollow: true } : { noIndex, follow: true };
}
