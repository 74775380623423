import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import './usurp-filters-panel.scss';

export function UsurpFiltersPanel(props) {
  const { children, className } = props;
  return <div className={classnames('usurp-filters-panel bg-cool-gray-100', className)}>{children}</div>;
}

UsurpFiltersPanel.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

UsurpFiltersPanel.defaultProps = {
  className: '',
};
