import React, { memo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { getCpoContent, getCpoMakeContentPath } from 'site-modules/shared/utils/cpo-helper';
import { connectToModel } from 'client/data/luckdragon/redux/react-binding';
import { bindToContent, CmsEntities } from 'client/data/models/cms';
import { ContentSection } from 'site-modules/shared/components/content-section/content-section';
import { makeNiceName } from 'site-modules/shared/utils/nice-name';
import { DEFAULT_CONTENT } from 'client/data/cms/content';
import { ContentFragment } from 'site-modules/shared/components/content-fragment/content-fragment';
import { ScrollElement } from 'site-modules/shared/components/scroll-link/scroll-element';
import { CPO_JUMP_LINK_LOCATION } from 'site-modules/shared/constants/inventory/vdp-navigation';

import './certified-pre-owned.scss';

export const CertifiedPreOwnedUI = memo(({ certifiedPreOwned, make, isMainHeader, className }) => {
  const certifiedPreOwnedContents = getCpoContent(certifiedPreOwned);
  const hasMultiCpoProgram = certifiedPreOwnedContents && certifiedPreOwnedContents.length > 1;

  const title = `${make} Certified Pre-Owned Vehicle Program`;

  return (
    certifiedPreOwnedContents && (
      <ContentSection
        className={classNames('certified-pre-owned', className)}
        dataTrackingParent="edm-certified-pre-owned"
      >
        <ScrollElement id={CPO_JUMP_LINK_LOCATION}>
          <div className="d-flex align-items-center justify-content-between">
            <h3 className={classNames(isMainHeader ? 'heading-4' : 'heading-5')}>{title}</h3>
          </div>

          <div className={classNames({ row: hasMultiCpoProgram })}>
            {certifiedPreOwnedContents.map((cpoContent, ind) => (
              <ContentFragment
                // eslint-disable-next-line react/no-array-index-key
                key={ind}
                classes={classNames('body', { 'col-xl-6 col-12 mb-2_5': hasMultiCpoProgram })}
              >
                {cpoContent}
              </ContentFragment>
            ))}
          </div>
        </ScrollElement>
      </ContentSection>
    )
  );
});
CertifiedPreOwnedUI.propTypes = {
  certifiedPreOwned: CmsEntities.Content,
  make: PropTypes.string,
  isMainHeader: PropTypes.bool,
  className: PropTypes.string,
};

CertifiedPreOwnedUI.defaultProps = {
  certifiedPreOwned: DEFAULT_CONTENT,
  make: '',
  isMainHeader: false,
  className: '',
};

export const stateToPropsConfig = {
  certifiedPreOwned: bindToContent(({ make }) => getCpoMakeContentPath(makeNiceName(make))),
};

export const CertifiedPreOwned = connectToModel(CertifiedPreOwnedUI, stateToPropsConfig);
