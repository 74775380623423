import React, { useCallback } from 'react';
import { bindToPath, connectToModel } from 'client/data/luckdragon/redux/react-binding';
import { InventoryModel, InventoryPaths } from 'client/data/models/inventory';
import {
  getIntervalsForPaymentType,
  PricingHistogram,
} from 'site-modules/shared/components/inventory/usurp-histogram/pricing-histogram';

const usurpPricingHistogramDataProvider = WrappedComponent => {
  const ComponentUI = ({ setModelValue, ...restProps }) => {
    const updateQuery = useCallback(
      query => {
        setModelValue(InventoryPaths.buildSearchResultsPriceIntervalsQueryPath(), InventoryModel, query);
      },
      [setModelValue]
    );

    return <WrappedComponent {...restProps} onUpdateQuery={updateQuery} />;
  };

  const Component = connectToModel(ComponentUI, {
    intervals: bindToPath(
      InventoryPaths.buildSearchResultsPriceIntervalsPath(),
      InventoryModel,
      (data, { facetType }) => getIntervalsForPaymentType(data, facetType),
      false
    ),
    queryForByIntervalAggregation: bindToPath(
      'searchResults',
      InventoryModel,
      data => data?.attributes?.queryForByIntervalAggregation,
      false
    ),
  });

  Component.displayName = `usurpPricingHistogramDataProvider(${WrappedComponent.name || WrappedComponent.displayName})`;

  return Component;
};

export const UsurpPricingHistogramDataProvider = usurpPricingHistogramDataProvider(PricingHistogram);
