import React, { useEffect, useState, useRef, memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'reactstrap';
import { get, inRange } from 'lodash';
import { InventoryEntities } from 'client/data/models/inventory';
import {
  UsurpAdsConfig,
  MINIMUM_CARDS,
} from 'site-modules/shared/components/inventory/usurp-card-list/usurp-card-list-ad-units-data';
import { Storage } from 'site-modules/shared/utils/storage';
import { RECENTLY_VIEWED_STORAGE } from 'site-modules/shared/components/inventory/constants/recently-viewed-vins';
import { InventoryAdTargetingPrefetch } from 'site-modules/shared/components/native-ad/inventory-ad-targeting/inventory-ad-targeting-prefetch';

function isLazyLoadImg({ index }) {
  return !inRange(index, 0, 1);
}

export const UsurpCardList = memo(props => {
  const { inventory, cardComponent: CardComponent, cardKeyPrefix, ...rest } = props;

  const [recentlyViewedVins, setRecentlyViewedVins] = useState([]);
  const storage = useRef(new Storage('localStorage'));

  useEffect(() => {
    const vins = storage.current.get(RECENTLY_VIEWED_STORAGE.PATH) || [];
    if (vins && vins.length) {
      setRecentlyViewedVins(vins);
    }
  }, []);

  const renderCard = useCallback(
    (vehicle, index) => (
      <Col
        tag="li"
        xs={12}
        md={6}
        key={`${cardKeyPrefix ? `${cardKeyPrefix}_` : ''}${vehicle.vin}`}
        className="d-flex mb-0_75 mb-md-1_5"
      >
        <CardComponent
          lazyLoadImage={isLazyLoadImg({ index })}
          vehicle={vehicle}
          importance={index === 0 ? 'high' : null}
          isViewed={recentlyViewedVins.includes(get(vehicle, 'vin'))}
          {...rest}
        />
      </Col>
    ),
    [cardKeyPrefix, recentlyViewedVins, rest]
  );

  const renderCardListWithAds = useCallback(() => {
    const cardListWithAds = [];

    inventory.forEach((vehicle, index) => {
      cardListWithAds.push(renderCard(vehicle, index));
      if (UsurpAdsConfig[index + 1]) {
        cardListWithAds.push(UsurpAdsConfig[index + 1].component(InventoryAdTargetingPrefetch));
      }
    });

    if (inventory.length < MINIMUM_CARDS) {
      cardListWithAds.push(UsurpAdsConfig.default.component(InventoryAdTargetingPrefetch));
    } else if (UsurpAdsConfig.last) {
      cardListWithAds.push(UsurpAdsConfig.last.component(InventoryAdTargetingPrefetch));
    }

    return cardListWithAds;
  }, [inventory, renderCard]);

  return (
    <Row
      tag="ul"
      data-tracking-parent="edm-entry-vehicle-card-list"
      className="usurp-card-list list-unstyled align-items-stretch"
    >
      {renderCardListWithAds()}
    </Row>
  );
});

UsurpCardList.displayName = 'UsurpCardList';

UsurpCardList.propTypes = {
  inventory: InventoryEntities.InventoriesVin,
  cardComponent: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  cardKeyPrefix: PropTypes.string,
};

UsurpCardList.defaultProps = {
  inventory: [],
  cardComponent: null,
  cardKeyPrefix: undefined,
};
