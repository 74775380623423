import { SCHEMA_URL } from 'site-modules/shared/pages/seo';

/**
 * Simple webpage schema for json+ld
 * @param {string} title
 */
export const getWebpageSchema = title => ({
  '@context': SCHEMA_URL,
  '@type': 'WebPage',
  name: title,
});
