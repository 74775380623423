import { get } from 'lodash';
import { SCHEMA_URL } from 'site-modules/shared/pages/seo';
import { isUsed } from 'site-modules/shared/utils/inventory-utils/is-used';
import { getVehicleDescription } from 'site-modules/shared/utils/inventory-utils/get-vehicle-description';

import { getAggregateOfferSchema } from './offer';
import { getAggregateRatingFromCoreConsumerReviews, getConsumerReviewSchema } from './review';

export function getProductSchema({
  searchResults,
  image,
  consumerReviews,
  description,
  vehicleType = '',
  make = '',
  model = '',
  year = '',
  isSeot4148 = false,
}) {
  const vehicle = get(searchResults, 'inventories.results[0]', {});
  const reviewsNumber = get(consumerReviews, 'totalReviews');
  const aggregateOffer = getAggregateOfferSchema({ searchResults });

  const newOrUsedType = get(vehicle, 'type', '');
  let productName;
  if (year && make && model) {
    productName = `${year} ${make} ${model}`;
  } else if (make && model) {
    productName = `${make} ${model}`;
  } else if (make && vehicleType) {
    productName = `${make} ${vehicleType}`;
  } else if (vehicleType) {
    productName = vehicleType;
  } else if (make) {
    productName = make === 'Honda' ? 'Honda Motor Company' : make;
  } else {
    productName = 'Cars';
  }

  return aggregateOffer
    ? {
        '@context': SCHEMA_URL,
        '@type': isSeot4148 ? 'Vehicle' : 'Product',
        name: productName,
        description: description || getVehicleDescription(vehicle),
        image: get(image, 'url', ''),
        itemCondition: isUsed(newOrUsedType) ? `${SCHEMA_URL}/UsedCondition` : `${SCHEMA_URL}/NewCondition`,
        brand: {
          '@type': 'Brand',
          name: make,
        },
        offers: aggregateOffer,
        ...(reviewsNumber && {
          aggregateRating: getAggregateRatingFromCoreConsumerReviews(consumerReviews),
          review: getConsumerReviewSchema({ consumerReviews, includeReviewedItem: false }),
        }),
      }
    : null;
}
