import React from 'react';
import PropTypes from 'prop-types';

import { Link } from 'site-modules/shared/components/link/link';

/**
 * Renders table body.
 * @param {object[]} records
 * @returns {ReactElement}
 */
export function LinkWidgetTableBody({ records }) {
  return (
    <tbody>
      {records.map(({ items }) => {
        const rowKey = items[0].anchorText;
        return (
          <tr key={rowKey}>
            {items.map(({ anchorText, href, type }, ind) =>
              ind === 0 ? (
                <th scope="row" key={`${rowKey} ${type}`}>
                  {href ? <Link to={href}>{anchorText}</Link> : anchorText}
                </th>
              ) : (
                <td key={`${rowKey} ${type}`}>{href ? <Link to={href}>{anchorText}</Link> : anchorText}</td>
              )
            )}
          </tr>
        );
      })}
    </tbody>
  );
}

LinkWidgetTableBody.propTypes = {
  records: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};
