import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { noop } from 'lodash';
import dateFormat from 'dateformat';
/* Models */
import { bindToPath, connectToModel } from 'client/data/luckdragon/redux/react-binding';
import { FinancingModel, PATHS } from 'client/data/models/financing';
/* Utils */
import { getStaticImageUrl } from 'client/utils/image-helpers';
import { addDaysToCurrentDate, adjustTimezone } from 'site-modules/shared/utils/date-utils';
import { formatPriceStringV2 } from 'site-modules/shared/utils/price-utils';
/* Constants */
import { GO_EXPIRATION_PRE_QUALIFICATION_DAYS } from 'client/site-modules/shared/constants/financing/financing';
import { CREDIT_PROVIDER } from 'site-modules/shared/constants/allowed-inventory-request-params';
import { CAPITAL_ONE_CREDIT_PROVIDER } from 'site-modules/shared/constants/inventory/static-facets';

export function CapOnePrequalApprovedMsgUI({ isMobile, onUpdate, onRemoveCapOnePrequalParam, appraisalOffer }) {
  const removeFilter = useCallback(
    () =>
      onUpdate(
        CREDIT_PROVIDER,
        { name: CREDIT_PROVIDER, value: CAPITAL_ONE_CREDIT_PROVIDER, type: CREDIT_PROVIDER },
        false
      ),
    [onUpdate]
  );

  const isTradeInApplied = !!appraisalOffer?.appliedTradeIn;
  const title = `${isMobile ? '' : 'Great News! '}You are prequalified by Capital One!`;
  const copy = `${
    isTradeInApplied
      ? 'Currently showing dealers that partner with Capital One.'
      : "You're now shopping dealers that partner with Capital One."
  }`;

  return (
    <div
      className="bg-white"
      style={{ borderRadius: '12px', position: 'relative' }}
      data-tracking-parent="prequal-approval-message"
    >
      <div style={{ ...(!isMobile && { display: 'flex' }), marginBottom: '16px' }}>
        <div
          className={classNames(isTradeInApplied ? 'bg-green-50' : 'bg-blue-50')}
          style={{
            padding: isMobile ? '8px 16px' : '8px 24px',
            display: 'flex',
            alignItems: 'center',
            borderTopLeftRadius: '12px',
            ...(isMobile ? { borderTopRightRadius: '12px' } : { borderBottomLeftRadius: '12px' }),
          }}
        >
          <img
            src={getStaticImageUrl('/img/car-buying/capital1/celebrate-party_popper-icon.svg')}
            width={isMobile ? 31 : 76}
            height={isMobile ? 32 : 78}
            aria-hidden
            alt=""
          />
          {isMobile && (
            <div className="text-white" style={{ fontSize: '24px', fontWeight: 'bold', marginLeft: '8px' }}>
              Great News!
            </div>
          )}
        </div>
        <div
          className="bg-white"
          style={{
            padding: isMobile ? '16px' : '12px 16px',
            borderBottomRightRadius: '12px',
            ...(isMobile ? { borderBottomLeftRadius: '12px' } : { borderTopRightRadius: '12px' }),
          }}
        >
          <img
            style={{ display: 'block' }}
            width={89}
            height={32}
            src={getStaticImageUrl('/img/car-buying/capital1/cap-one_srp.png')}
            alt="Capital One"
          />
          <div
            className={classNames(isTradeInApplied ? 'text-green-40' : 'text-blue-30')}
            style={{ fontSize: '24px', fontWeight: 'bold' }}
          >
            {title}
          </div>
          <div className="text-cool-gray-30" style={{ fontSize: '12px' }}>
            Valid through{' '}
            {dateFormat(adjustTimezone(addDaysToCurrentDate(GO_EXPIRATION_PRE_QUALIFICATION_DAYS), 'mm-dd-yyyy'))}
          </div>
          {isTradeInApplied && (
            <div style={{ margin: '12px 0' }}>
              Your {formatPriceStringV2(appraisalOffer?.appliedTradeIn)} trade-in will be automatically applied to
              payments you view.
            </div>
          )}
          <div style={{ marginTop: '20px', fontSize: isTradeInApplied ? '12px' : '16px' }}>
            {copy}{' '}
            <button
              className="btn text-blue-40"
              style={{
                fontSize: '12px',
                background: 'transparent',
                boxShadow: 'none',
                padding: '0',
              }}
              onClick={removeFilter}
              data-testid="toggle-btn"
              data-tracking-id="toggle_financing"
              data-tracking-value={`Prequalification~false~Dealers that accept my prequalification`}
            >
              Remove Filter
            </button>
          </div>
          <button
            className={classNames('btn p-0 ', isMobile ? 'text-white' : 'text-cool-gray-50')}
            style={{
              position: 'absolute',
              right: isMobile ? '16px' : '12px',
              top: '8px',
              background: 'transparent',
              boxShadow: 'none',
              fontSize: isMobile ? '16px' : '14px',
            }}
            onClick={onRemoveCapOnePrequalParam}
            data-tracking-id="close_prequal_approved_message"
            data-testid="btn-close"
            aria-label="close"
          >
            <i className="icon-cross2" aria-hidden />
          </button>
        </div>
      </div>
    </div>
  );
}

CapOnePrequalApprovedMsgUI.propTypes = {
  isMobile: PropTypes.bool,
  onUpdate: PropTypes.func,
  onRemoveCapOnePrequalParam: PropTypes.func,
  appraisalOffer: PropTypes.shape({}),
};

CapOnePrequalApprovedMsgUI.defaultProps = {
  isMobile: false,
  onUpdate: noop,
  onRemoveCapOnePrequalParam: noop,
  appraisalOffer: null,
};

export const stateToPropsConfig = {
  appraisalOffer: bindToPath(PATHS.APPRAISAL_OFFER, FinancingModel),
};

export const CapOnePrequalApprovedMsg = connectToModel(CapOnePrequalApprovedMsgUI, stateToPropsConfig);
