import React from 'react';
import PropTypes from 'prop-types';

/**
 * Renders table head.
 * @param {object[]} headers
 * @param {booleaen} isMobile
 * @param {string} className
 * @returns {ReactElement}
 */
export function LinkWidgetTableHead({ headers, className }) {
  return (
    <thead>
      <tr className={className}>
        {headers.map(header => (
          <th key={header}>{header}</th>
        ))}
      </tr>
    </thead>
  );
}

LinkWidgetTableHead.propTypes = {
  headers: PropTypes.arrayOf(PropTypes.string).isRequired,
  className: PropTypes.string,
};

LinkWidgetTableHead.defaultProps = {
  className: 'text-gray-darker',
};
