import {
  generateSrpLinkUsedMake,
  generateSrpLinkMakeModel,
  generateSrpLinkUsedType,
  generateLeaseSrpLinkMakeModel,
  generateLeaseSrpLinkType,
  generateSrpLinkNewMake,
  generateSrpLinkNewMakeModel,
  generateSrpLinkNewType,
  getNewCarsForSaleSrp,
} from 'site-modules/shared/utils/srp-link-constructor';
import { makeNiceName } from 'site-modules/shared/utils/nice-name';
import { isNew } from 'site-modules/shared/utils/inventory-utils/is-new';
import { getBaseBreadcrumb, getHomeBreadcrumb, getUsedLandingBreadcrumb } from './helper';

const LEASE_INVENTORY_BREADCRUMB_TITLE = 'Lease Deals';

function generateLeaseMakeLandingLink(make) {
  return `/${makeNiceName(make)}-lease-deals/`;
}

/* START: LEASE BREADCRUMBS */
export function getLeaseBaseBreadcrumbs(title, link) {
  return getBaseBreadcrumb(`${title} ${LEASE_INVENTORY_BREADCRUMB_TITLE}`, link);
}

export function getLeaseMakeBreadcrumbs(make, invType) {
  return [getHomeBreadcrumb(), getLeaseBaseBreadcrumbs(make, generateLeaseMakeLandingLink(make, invType))];
}

export function getLeaseMakeModelBreadcrumbs(make, model, invType) {
  return [
    ...getLeaseMakeBreadcrumbs(make, invType),
    getLeaseBaseBreadcrumbs(`${make} ${model}`, generateLeaseSrpLinkMakeModel(make, model)),
  ];
}

export function getLeaseTypeBreadcrumbs(type, invType) {
  return [getHomeBreadcrumb(), getLeaseBaseBreadcrumbs(type, generateLeaseSrpLinkType(type, invType))];
}

export function getLeaseCarsForSaleBreadcrumbs() {
  return [getHomeBreadcrumb(), getBaseBreadcrumb(LEASE_INVENTORY_BREADCRUMB_TITLE)];
}
/* END: LEASE BREADCRUMBS */

/* START: MAKE TYPE BREADCRUMBS */
export function getMakeTypeBreadcrumbs({ make, vehicleCategory }) {
  return [
    getHomeBreadcrumb(),
    getBaseBreadcrumb(make, `/${makeNiceName(make)}/`),
    getBaseBreadcrumb(`${make} ${vehicleCategory}`),
  ];
}
/* END: MAKE TYPE BREADCRUMBS */

/* START: NEW/USED/CPO BREADCRUMBS */
/* invType options: New/Used/Used Certified Pre-Owned */

export function getCarsForSaleFlatBreadcrumbs(invType) {
  return [
    getHomeBreadcrumb(),
    isNew(invType) ? getBaseBreadcrumb('New Cars For Sale', getNewCarsForSaleSrp()) : getUsedLandingBreadcrumb(),
  ];
}

export function getMakeBreadcrumbs(make, invType) {
  return [
    ...getCarsForSaleFlatBreadcrumbs(invType),
    getBaseBreadcrumb(
      `${invType} ${make} For Sale`,
      isNew(invType) ? generateSrpLinkNewMake(make) : generateSrpLinkUsedMake(make, invType)
    ),
  ];
}

export function getMakeModelBreadcrumbs(make, model, invType) {
  return [
    ...getMakeBreadcrumbs(make, invType),
    getBaseBreadcrumb(
      `${invType} ${make} ${model} For Sale`,
      isNew(invType) ? generateSrpLinkNewMakeModel(make, model) : generateSrpLinkMakeModel(make, model, invType)
    ),
  ];
}

export function getTypeBreadcrumbs(type, invType) {
  return [
    ...getCarsForSaleFlatBreadcrumbs(invType),
    getBaseBreadcrumb(
      `${invType} ${type} For Sale`,
      isNew(invType) ? generateSrpLinkNewType(type) : generateSrpLinkUsedType(type, invType)
    ),
  ];
}
/* END: NEW/USED BREADCRUMBS */
