import { get } from 'lodash';
import { isRecurrentAvailable } from 'site-modules/shared/utils/inventory/is-recurrent-available';
import { isNew } from 'site-modules/shared/utils/inventory-utils/is-new';

export const isInventoryWithRecurrentInfo = inventory => {
  const isElectric = get(inventory, 'vehicleInfo.partsInfo.engineType') === 'electric';
  const isRecurrent = isRecurrentAvailable(inventory);
  const { type } = inventory;
  return !isNew(type) && isElectric && isRecurrent;
};
