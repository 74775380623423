import { get, head } from 'lodash';
import { SCHEMA_URL } from 'site-modules/shared/pages/seo';
import { isUsed } from 'site-modules/shared/utils/inventory-utils/is-used';
import { getVehicleDescription } from 'site-modules/shared/utils/inventory-utils/get-vehicle-description';
import { transformVehicleHistory } from 'site-modules/shared/utils/inventory/srp-card-utils';
import { ALLOWED_HISTORY_PROVIDERS } from 'site-modules/shared/constants/inventory/vehicle-history';
import { showTrimName } from 'client/site-modules/shared/utils/show-trim-name';
import { getUsurpImages } from 'client/site-modules/shared/utils/inventory/srp-images';

import { getOfferVinSchema } from './offer';

export function getVehicleVinSchema({ vehicle, disableInventoryMLPhotoSort, isSeot4290 }) {
  const { type = '', stockNumber, vin } = vehicle;
  const { make, model, year, style, trim = '' } = get(vehicle, 'vehicleInfo.styleInfo', {});
  const { transmission, driveTrain } = get(vehicle, 'vehicleInfo.partsInfo', {});
  const mileage = get(vehicle, 'vehicleInfo.mileage', '');
  const historyInfo = get(vehicle, 'historyInfo', {});
  const { ownerText, usageType, historyProvider } = historyInfo;
  const { accidentText } = transformVehicleHistory(historyInfo);
  const cylinders = get(vehicle, 'vehicleInfo.partsInfo.cylinders', '');
  const { photoUrls } = getUsurpImages(vehicle, disableInventoryMLPhotoSort);
  const image = head(photoUrls);

  return {
    '@context': SCHEMA_URL,
    '@type': isSeot4290 ? 'Car' : 'Vehicle',
    name: `${year} ${make} ${model} ${style} ${showTrimName(trim) ? trim : ''}`,
    description: getVehicleDescription(vehicle),
    brand: {
      '@type': 'Brand',
      name: make,
    },
    ...(isSeot4290 && { vehicleModelDate: year, model }),
    bodyType: get(vehicle, 'vehicleInfo.styleInfo.bodyType'),
    itemCondition: isUsed(type) ? `${SCHEMA_URL}/UsedCondition` : `${SCHEMA_URL}/NewCondition`,
    image,
    color: get(vehicle, 'vehicleInfo.vehicleColors.exterior.name'),
    sku: stockNumber,
    driveWheelConfiguration: driveTrain,
    vehicleConfiguration: style,
    vehicleTransmission: transmission,
    vehicleIdentificationNumber: vin,
    mileageFromOdometer: mileage
      ? {
          '@type': 'QuantitativeValue',
          value: mileage,
          unitCode: 'SMI',
        }
      : undefined,
    vehicleEngine: cylinders
      ? {
          '@type': 'EngineSpecification',
          name: `${cylinders} Cyl`,
        }
      : undefined,
    offers: getOfferVinSchema({ vehicle }),
    ...(ALLOWED_HISTORY_PROVIDERS.includes(historyProvider) && {
      knownVehicleDamages: accidentText,
      numberOfPreviousOwners: ownerText,
      vehicleSpecialUsage: usageType,
    }),
  };
}
