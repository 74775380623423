import { parseRange } from 'site-modules/shared/utils/inventory/search-filter';

const comboPageMinYear = 2022;

export function isComboPageYear(year) {
  return year >= comboPageMinYear;
}

export function isUsedSRPReviewComboPage(urlContext, selectedFacets) {
  return !!urlContext?.isSrpOnUsedCore && isComboPageYear(parseRange(selectedFacets?.year?.[0])?.min);
}
