import {
  DELIVERY_AVAILABLE,
  DELIVERY_TYPE_TITLES,
  STATES_WITH_DELIVERY_RESTRICTIONS,
} from 'site-modules/shared/components/delivery/constants';

/**
 * Returns whenever visitor has restrictions by state code.
 * @param {string} visitorStateCode
 */
export function hasDeliveryRestrictions(visitorStateCode) {
  return STATES_WITH_DELIVERY_RESTRICTIONS.includes(visitorStateCode);
}

/**
 * Returns delivery type title for free or paid types.
 * @param {object} vinData
 * @param {string} visitorStateCode
 * @returns {string}
 */
export function getDeliveryTypeTitle(vinData, visitorStateCode) {
  const deliveryTypeTitle = DELIVERY_TYPE_TITLES[vinData.deliveryOptions && vinData.deliveryOptions.deliveryCode] || '';
  return deliveryTypeTitle && hasDeliveryRestrictions(visitorStateCode) ? DELIVERY_AVAILABLE : deliveryTypeTitle;
}

/**
 * Checks delivery availability.
 * @param {object} vinData
 * @param {string} visitorStateCode
 * @returns {boolean}
 */
export function isDeliveryAvailable(vinData, visitorStateCode) {
  return (
    !!DELIVERY_TYPE_TITLES[vinData.deliveryOptions && vinData.deliveryOptions.deliveryCode] &&
    !hasDeliveryRestrictions(visitorStateCode)
  );
}
