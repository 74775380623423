import { capitalize, compact, get } from 'lodash';
import { getFeaturesString } from './get-features-string';
import { isUsed } from './is-used';

/**
 * Get vehicle's description from inventory item (E.g. Used 2015 Honda Accord EX-L with Tire Pressure Warning, Audio.)
 *
 * @param {Object} vinData Vehicle data.
 * @returns {String}
 */
export function getVehicleDescription(vinData) {
  const type = get(vinData, 'type', '');
  const { make, model, year, style } = get(vinData, 'vehicleInfo.styleInfo', {});
  const isUsedType = isUsed(type);
  const itemCondition = isUsedType ? 'Used' : 'New';
  const featuresStr = getFeaturesString(vinData);
  const partsInfo = get(vinData, 'vehicleInfo.partsInfo', {});
  const engine = get(partsInfo, 'cylinders', '');
  const transmission = capitalize(get(partsInfo, 'transmission', ''));
  const driveTrain = get(partsInfo, 'driveTrain', '');
  const specsString = compact([
    featuresStr,
    engine && `${engine}-cylinders engine`,
    transmission && `${transmission} transmission`,
    driveTrain && `${driveTrain} drive wheel configuration`,
  ]).join(', ');

  return `${itemCondition} ${year} ${make} ${model} ${style}${specsString ? ` with ${specsString}` : ''}`;
}
